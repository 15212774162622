import React from "react"
import { Link, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const BlogPostContents = ({ title, date, content }) => (
  <article className="blog-post">
    <header className="max-w-2xl my-2 md:my-6 mx-auto text-center">
      <h1 className="text-gray-900 font-bold">{title}</h1>
      <p className="block text-gray-700">{date}</p>
    </header>
    <section dangerouslySetInnerHTML={{ __html: content }} />
  </article>
)

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteUrl = data.site.siteMetadata.siteUrl
  const { previous, next } = pageContext
  const featuredImage = post.frontmatter.featuredImage
  const menuBackgroundImage = getSrc(
    featuredImage.src?.childImageSharp?.fluid) ?? null
  const metaImage = getSrc(featuredImage.src?.meta?.fixed) ?? null

  const additionalMetas: Array<{
    property: string
    content: string | number
  }> = [
    {
      property: `article:published_time`,
      content: post.frontmatter.metaDate,
    },
  ]

  if (metaImage != null) {
    additionalMetas.push({
      property: "twitter:image",
      content: siteUrl + metaImage,
    })
    additionalMetas.push({
      property: "og:image",
      content: siteUrl + metaImage,
    })
    additionalMetas.push({
      property: `og:image:height`,
      content: 150,
    })
    additionalMetas.push({
      property: `og:image:width`,
      content: 280,
    })
  }
  if (featuredImage.alt != null) {
    additionalMetas.push({
      property: `twitter:image:alt`,
      content: featuredImage.alt,
    })
    additionalMetas.push({
      property: `og:image:alt`,
      content: featuredImage.alt,
    })
  }

  return (
    <Layout menuBackgroundImage={menuBackgroundImage} location={location}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.metaDescription || post.excerpt}
        meta={additionalMetas}
      />
      <BlogPostContents
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        content={post.html}
      />
      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`query BlogPostBySlug($slug: String!) {
  site {
    siteMetadata {
      siteUrl
    }
  }
  markdownRemark(fields: {slug: {eq: $slug}}) {
    id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
          metaDate: date
          date(formatString: "dddd, MMMM DD, Y")
          description
          metaDescription
          featuredImage {
            alt
            src {
              meta: childImageSharp {
                 fixed: gatsbyImageData(width: 280, height: 150, transformOptions: { cropFocus: CENTER }, layout: FIXED)               }     
              childImageSharp {
                fluid: gatsbyImageData(layout: FIXED) 
              }
          }
      }
    }
  }
}
`
